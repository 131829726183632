import * as React from "react";
import { Container, Paper, Stack, Box, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import AirIcon from '@mui/icons-material/Air';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';

import "./PersonalPage.css"
import { AnimatedLink, SectionBreak } from "../../components";

function PersonalPage(){

    return(
        <Container id="personalContainer">
            <Stack direction="column" spacing={6}>
            <Paper elevation={8} sx={{p: 6}}>
                <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={10}>
                <StaticImage src="../../icons/cartoon.png" layout="fixed" height={130} width={130} alt="My Face." />
                <Stack direction="column" spacing={2}>
                <h2>Hello!</h2>
                <p>I&apos;m Daniel. Welcome to my site!</p>
                <p>Here, you&apos;ll find my <AnimatedLink to="category/projects">projects</AnimatedLink>, <AnimatedLink to="photos">photos</AnimatedLink>, and <AnimatedLink to="category/blog">blog</AnimatedLink>.</p>
                </Stack>
                </Stack>
                </Stack>
            </Paper>

            <SectionBreak text="In a nutshell..."/>


            <Stack direction="row" spacing={10} justifyContent="center" alignItems="stretch" textAlign="center">
                <Paper elevation={4} className="imageBox"> 
                <StaticImage src="../../icons/beach.png" height={200} alt="Photo of a beach"/>
                <p> Grew up in Florida. </p>
                </Paper>
                <Box 
                    display="flex" 
                    alignItems="center"
                    justifyContent="center"
                >
                <FollowTheSignsIcon sx={{fontSize: 80, color: "#972738"}}/>
                </Box>
                <Paper elevation={4} className="imageBox"> 
                <StaticImage src="../../icons/logos/Stanford_logo.png" height={200} alt="Stanford logo"/>
                <p> Studied at Stanford. </p>
                </Paper>  
                <Box 
                    display="flex" 
                    alignItems="center"
                    justifyContent="center"
                >
                <AirIcon sx={{fontSize: 80, color: "#972738"}}/>
                </Box>  
                <Paper elevation={4} className="imageBox"> 
                <StaticImage src="../../icons/inference_logo.png" height={180} alt="Healthcare"/>
                <p> Now I build healthcare technology. </p>
                </Paper>
            </Stack>

            <SectionBreak text="Talk to me about:"/>

            <Grid container justifyContent="space-between" alignItems="stretch">
            <Grid item xs={12} md={6} padding={2}>
                <Paper elevation={4} className="imageBox"> 
                    <StaticImage src="../../icons/visitedCountries.png" height={200} alt="My visited countries"/>
                    <p> Globetrotting! </p>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} padding={2}>
                <Paper elevation={4} className="imageBox"> 
                    <StaticImage src="../../icons/healthcare.png" height={200} alt="Healthcare icon"/>
                    <p> Healthcare! </p>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} padding={2}>
                <Paper elevation={4} className="imageBox"> 
                    <StaticImage src="../../icons/scream.png" height={200} alt="An AI-altered version of me"/>
                    <p> Deep Learning! </p>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} padding={2}>
                <Paper elevation={4} className="imageBox"> 
                    <StaticImage src="../../icons/EA.png" height={200} alt="Effective Altruism"/>
                    <p> (In)effective Altruism! </p>
                </Paper>
            </Grid>
            </Grid>

            {/* <SectionBreak text="Some fun bits:"/>
            Nato Accredited reporter, attended the 2022 Madrid Summit -- FUN FACT
            Travelled to Ukraine during the 2022 Russian invasion, produced and filmed a documentary on the ground
            Private pilot in progress
            AI-generated music albumn
            Couchsurfed Europe
            Open Water PADI Diver
            Digital Nomad in Thailand
            Started the <AnimatedLink to="www.stai.stanford.edu">Stanford Trustworthy AI Lab.</AnimatedLink> */}

            </Stack>
        </Container>
    );
}

export default PersonalPage;